import React from 'react';
import '../styles/ChatMessage.css';

const ChatMessage = ({ message, userClass = 'user-message', agentClass = 'agent-message' }) => {
  const isUser = message.sender === 'user';
  
  return (
    <div className={`chat-message ${isUser ? userClass : agentClass}`}>
      <div className="message-text">{message.text}</div>
    </div>
  );
};

export default ChatMessage;

