
import React from 'react';
import styled from 'styled-components';
import Header from '../components/Header';
import TypingEffect from "../components/TypingEffect";

const Container = styled.div`
  color: ${({ theme }) => theme.colors.text};
  background: ${({ theme }) => theme.colors.background};
  min-height: 100vh;
  display: flex;
  flex-direction: column;
`;
const paragraph = "For example: I'm looking to buy a new single-family home in Orlando, zip code 32810. It'll be my primary residence and is worth around $500,000. I need a loan of $400,000.";


const HeroSection = styled.section`
  padding: 5rem 2rem;
  text-align: center;
  background: ${({ theme }) => theme.colors.primaryGradient};
  color: ${({ theme }) => theme.colors.primary};
  clip-path: polygon(0 0, 100% 0, 100% 90%, 0% 100%);
  box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.1);
  margin-top: 80px;

  h1 {
    font-size: 3rem;
    font-weight: bold;
    margin-bottom: 1.5rem;
    text-shadow: 2px 2px 6px rgba(0, 0, 0, 0.2);
  }

  p {
    font-size: 1.2rem;
    margin-top: 1rem;
    line-height: 1.6;
    max-width: 800px;
    margin: auto;
  }

  @media (max-width: 768px) {
    margin-top: 120px;
  }
`;



const ChatbotSection = styled.div`
  margin-top: 3rem;
  padding: 4rem 2rem;
  background: ${({ theme }) => theme.colors.secondaryGradient};
  color: ${({ theme }) => theme.colors.text};
  text-align: center;
  border-radius: 12px;
  max-width: 900px;
  margin: 3rem auto;
  box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.1);

  h2 {
    font-size: 2.4rem;
    font-weight: bold;
    margin-bottom: 1rem;
    color: ${({ theme }) => theme.colors.primary};

  }

  p {
    margin-bottom: 2rem;
    font-size: 1.1rem;
  }
`;

const PhoneSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background: ${({ theme }) => theme.colors.secondaryBackground};
  padding: 3rem 0;
  margin-top: 2rem;

  h2 {
    font-size: 2rem;
    color: ${({ theme }) => theme.colors.primary};
    margin-bottom: 1.5rem;
  }

  p {
    margin-bottom: 2rem;
    font-size: 1.1rem;
    line-height: 1.6;
    color: ${({ theme }) => theme.colors.text};

  }

  .phone-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #fff;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    width: 80%;
    max-width: 500px;
  }

  .phone {
    font-size: 2.5rem;
    font-weight: bold;
    color: ${({ theme }) => theme.colors.primary};
  }

  .call-btn {
    padding: 1rem 2rem;
    margin-top: 1rem;
    background: ${({ theme }) => theme.colors.primary};
    color: white;
    border: none;
    border-radius: 8px;
    font-size: 1.2rem;
    cursor: pointer;
    transition: all 0.3s;

    &:hover {
      background: ${({ theme }) => theme.colors.accent};
      transform: translateY(-2px);
    }
  }

  @media (max-width: 768px) {
    .phone-container {
      width: 90%;
    }
  }
`;

const LinkSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background: ${({ theme }) => theme.colors.secondaryBackground};
  padding: 3rem 0;
  margin-top: 2rem;

  h3 {
    font-size: 2rem;
    color: ${({ theme }) => theme.colors.primary};
    margin-bottom: 1.5rem;
  }

  p {
    margin-bottom: 2rem;
    font-size: 1.1rem;
    line-height: 1.6;
    color: ${({ theme }) => theme.colors.text};
  }

  .link-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    background: #fff;
    padding: 2rem;
    border-radius: 8px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    width: 80%;
    max-width: 500px;
  }

  a {
    padding: 1rem 2rem;
    margin-top: 1rem;
    background: ${({ theme }) => theme.colors.primary};
    color: white;
    border: none;
    border-radius: 8px;
    font-size: 1.2rem;
    cursor: pointer;
    transition: all 0.3s;

    &:hover {
      background: ${({ theme }) => theme.colors.accent};
      transform: translateY(-2px);
    }
  }

  @media (max-width: 768px) {
    .link-container {
      width: 90%;
    }
  }
`;

const Myna = () => (
  <Container>
    <Header /> {/* Using the existing Header component */}

    <HeroSection>
      <h1>Myna - Discover Our Conversational AI Product</h1>
      <p>Experience seamless human like interactions</p>
    </HeroSection>
    <ChatbotSection>
      <h2>Wanna try it yourself!</h2>
      <PhoneSection>
        <p>You can try calling this number to submit loan application!</p>
        <TypingEffect text={paragraph} speed={150} />
        <div className="phone-container">
          <div className="phone">+1 323 991 0222</div>
          <button className="call-btn" onClick={() => window.location.href = 'tel:+13239910222'}>
            Call Now
          </button>
        </div>
      </PhoneSection>
      <LinkSection>
      <p>Our chat is human-like interaction and our conversations are transactional. You can try this chat to submit loan application</p>
      <div className="link-container">
      <h3>Our AI chat for loans and mortgages</h3>

        <a
          href="https://lendingman.loan-officer-ai.com/chatbot"
          target="_blank"
        >
          Chat Now
        </a>
        </div>
        </LinkSection>
    </ChatbotSection>

  </Container>
);

export default Myna;
