import React, { useRef, useState } from 'react';
import styled from 'styled-components';
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import PauseIcon from "@mui/icons-material/Pause";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";



const video2 = 'https://storage.googleapis.com/public-kudiltech-dot-com/public/video2.mp4';
const video3 = 'https://storage.googleapis.com/public-kudiltech-dot-com/public/video3.mp4';
const video2_720 = 'https://storage.googleapis.com/public-kudiltech-dot-com/public/video2-720.mp4';
const video3_720 = 'https://storage.googleapis.com/public-kudiltech-dot-com/public/video3-720.mp4';
const mynaVideo1 = 'https://storage.googleapis.com/public-kudiltech-dot-com/public/MynaVideo1.mp4';
const mynaVideo2 = 'https://storage.googleapis.com/public-kudiltech-dot-com/public/MynaVideo2.mp4';


const VideoPlayer = () => {
    const videoRef = useRef(null);
    const [isPlaying, setIsPlaying] = useState(false);
    const [progress, setProgress] = useState(0);
    const [currentTime, setCurrentTime] = useState("0:00");
    const [duration, setDuration] = useState("0:00");
    const [currentVideo, setCurrentVideo] = useState(mynaVideo1); // Start with video1
  
    const formatTime = (time) => {
      const minutes = Math.floor(time / 60);
      const seconds = Math.floor(time % 60);
      return `${minutes}:${seconds < 10 ? "0" : ""}${seconds}`;
    };
  
    const handlePlayPause = () => {
      const video = videoRef.current;
      if (isPlaying) {
        video.pause();
      } else {
        video.play();
      }
      setIsPlaying(!isPlaying);
    };
  
    const handleProgressClick = (e) => {
      const video = videoRef.current;
      const progressBar = e.target.getBoundingClientRect();
      const clickPosition = e.clientX - progressBar.left;
      const newTime = (clickPosition / progressBar.width) * video.duration;
      video.currentTime = newTime;
      setProgress((newTime / video.duration) * 100);
    };
  
    const updateProgress = () => {
      const video = videoRef.current;
      setProgress((video.currentTime / video.duration) * 100);
      setCurrentTime(formatTime(video.currentTime));
      setDuration(formatTime(video.duration));
    };
  
    const switchVideo = (video) => {
      setCurrentVideo(video);
      const videoElement = videoRef.current;
      videoElement.load(); // Load the new video
      setIsPlaying(false); // Pause when switching videos
    };
  
    const disablePrevious = currentVideo === mynaVideo1;
    const disableNext = currentVideo === mynaVideo2;
  
    return (
        <FirstFeatureCard>
          <video
            ref={videoRef}
            onTimeUpdate={updateProgress}
            onLoadedMetadata={(e) => setDuration(formatTime(e.target.duration))}
          >
            <source src={currentVideo} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <div className="controls">
            <button
              className="control-button"
              onClick={() => switchVideo(mynaVideo1)}
              disabled={disablePrevious}
            >
              <ArrowBackIcon />
            </button>
            <button
              className="control-button"
              onClick={handlePlayPause}
            >
              {isPlaying ? <PauseIcon /> : <PlayArrowIcon />}
            </button>
            <div className="progress-bar" onClick={handleProgressClick}>
              <div style={{ width: `${progress}%` }}></div>
            </div>
            <span className="time">
              {currentTime} / {duration}
            </span>
            <button
              className="control-button"
              onClick={() => switchVideo(mynaVideo2)}
              disabled={disableNext}
            >
              <ArrowForwardIcon />
            </button>
          </div>
        </FirstFeatureCard>
    );
  };
  const FirstFeatureCard = styled.div`
    width: 90%;
    max-width: 1400px;
    height: 400px;
    background: white;
    border-radius: 16px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1rem;
    position: relative;
    overflow: hidden;
  
    video {
      width: 100%;
      height: 100%;
      border-radius: 12px;
      object-fit: cover;
    }
  
    .controls {
      position: absolute;
      bottom: 20px;
      left: 50%;
      transform: translateX(-50%);
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 90%;
      max-width: 600px;
      padding: 4px 7px;
      background: rgba(0, 0, 0, 0.5);
      border-radius: 10px;
      gap: 10px;
  
      @media (max-width: 768px) {
        flex-direction: column;
        gap: 4px;
        width: 35%;
      }
    }
  
    .control-button {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 20px;
      height: 20px;
      background: white;
      border: none;
      border-radius: 10%;
      box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
      cursor: pointer;
      transition: transform 0.2s ease, background 0.3s ease;
  
      svg {
        color: #1565c0;
        font-size: 20px;
      }
  
      &:hover {
        background: #e3f2fd;
        transform: scale(1.1);
      }
  
      &:disabled {
        background: #f0f0f0;
        cursor: not-allowed;
      }
  
      @media (max-width: 768px) {
        width: 20px;
        height: 20px;
      }
    }
  
    .progress-bar {
      flex: 1;
      height: 4px;
      margin: 0 10px;
      background: rgba(255, 255, 255, 0.4);
      border-radius: 2px;
      overflow: hidden;
      position: relative;
      cursor: pointer;
  
      &:hover {
        background: rgba(255, 255, 255, 0.6);
      }
  
      & > div {
        height: 100%;
        background: linear-gradient(90deg, #42a5f5, #1e88e5);
        transition: width 0.1s linear;
      }
    }
  
    .time {
      font-size: 12px;
      color: white;
      font-weight: bold;
      background: rgba(0, 0, 0, 0.5);
      padding: 4px 8px;
      border-radius: 8px;
      box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  
      @media (max-width: 768px) {
        font-size: 10px;
      }
    }
  `;
  
  const FeaturesSection = styled.section`
    padding: 2rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 2rem;
  
    @media (max-width: 768px) {
      padding: 1rem;
    }
  `;
  
  const FeatureGrid = styled.div`
    display: flex;
    width: 100%; /* Ensure grid aligns with the first card */
    max-width: 2000px; /* Increase the grid width */
    justify-content: space-between; /* Space cards evenly */
    gap: 2rem; /* Add space between cards */
    flex-wrap: wrap; /* Ensure responsiveness */
  
    @media (max-width: 768px) {
      flex-direction: column; /* Stack cards on smaller screens */
      align-items: center; /* Center cards when stacked */
    }
  `;
  
  const GridCard = styled.div`
    flex: 1 1 48%; /* Take up 48% of the width */
    max-width: 900px; /* Max width of each card */
    height: 1400px; /* Maintain container height */
    background: white;
    border-radius: 16px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start; /* Start from the top */
    padding: 1rem;
    text-align: center;
  
    @media (max-width: 768px) {
      flex: 1 1 100%; /* Expand card width on smaller screens */
      height: auto; /* Adjust height dynamically for smaller screens */
    }
  
    h3 {
      font-size: 1.5rem;
      color: ${({ theme }) => theme.colors.primary};
      margin-bottom: 0.5rem; /* Space below the heading */
    }
  
    p {
      font-size: 1rem;
      color: ${({ theme }) => theme.colors.textSecondary};
      margin-bottom: 1rem; /* Add space below the paragraph */
      border-bottom: 2px solid ${({ theme }) => theme.colors.primary}; /* Add bottom border */
      padding-bottom: 1rem; /* Add space below the border */
    }
  
    video {
      width: 90%; /* Set video width relative to container */
      max-width: 800px; /* Ensure videos don’t exceed this size */
      height: auto; /* Maintain aspect ratio */
      border-radius: 12px; /* Rounded corners */
      object-fit: fill; /* Make video fit without cropping */
      margin-top: 1rem; /* Space between video and other elements */
    }
  
    /* Specific styles for mobile view */
    @media (max-width: 768px) {
      video {
        width: 100%; /* Full width on smaller screens */
        height: auto; /* Maintain aspect ratio */
        max-height: 400px; /* Ensure it doesn’t become too tall */
      }
    }
  `;
  
  
  const ContentSection = styled.section`
    padding: 3rem 2rem;
    max-width: 1400px;
    margin: 0 auto;
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(220px, 1fr)); /* Reduced min width for smaller devices */
    gap: 1.5rem;
  
    .tile {
      background: #ffffff; /* Set tile background to pure white */
      padding: 1.5rem;
      border-radius: 12px;
      box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
      text-align: left;
      transition: transform 0.3s, box-shadow 0.3s;
  
      &:hover {
        transform: translateY(-5px);
        box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.2);
      }
  
      h3 {
        font-size: 1.5rem;
        color: ${({ theme }) => theme.colors.primary};
        margin-bottom: 1rem;
      }
  
      p {
        font-size: 1rem;
        line-height: 1.6;
        color: ${({ theme }) => theme.colors.text};
        margin-bottom: 1rem;
      }
  
      ul {
        margin: 0;
        padding-left: 1.5rem;
        list-style-type: disc;
  
        li {
          font-size: 0.95rem;
          color: ${({ theme }) => theme.colors.textLight};
          margin-bottom: 0.5rem;
          line-height: 1.4;
        }
      }
    }
  
    @media (max-width: 768px) {
      padding: 2rem 1rem;
      grid-template-columns: 1fr; /* Single-column layout for small screens */
  
      .tile {
        padding: 1.2rem;
        h3 {
          font-size: 1.3rem;
        }
        p,
        li {
          font-size: 0.9rem;
        }
      }
    }
  `;
  const Features = () => {
    return (
    <FeaturesSection>
      <VideoPlayer />
      <ContentSection>
      <div className="tile">
          <h3>What is Myna?</h3>
          <p>
          Myna, powered by KudilTech's conversational AI, automates customer interactions for finance companies. This allows businesses to:        </p>
          <ul>
            <li>Provide 24/7 customer support</li>
            <li>Automate complex tasks like mortgage loan inquiries and applications</li>
            <li>Free up employees to focus on strategic initiatives</li>
          </ul>
        </div>
      <div className="tile">
          <h3>How Myna Helps Your Business</h3>
          <p>
          Myna, a cutting-edge AI solution from KudilTech, revolutionizes customer interactions for finance companies. By automating tasks like:        </p>
          <ul>
            <li>Answering customer queries</li>
            <li>Processing mortgage loan applications</li>
            <li>Providing personalized recommendations</li>
          </ul>
          <p>
          Myna empowers your business to:
          </p>
          <ul>
            <li>Improve customer satisfaction</li>
            <li>Increase operational efficiency</li>
            <li>Generate more revenue</li>
          </ul>
        </div>
        <div className="tile">
          <h3>A Better Customer Experience with Myna</h3>
          <p>
          Myna, powered by KudilTech's AI, offers a seamless and efficient customer experience. With Myna, your customers can:        </p>
          <ul>
            <li>Get instant answers to their questions</li>
            <li>Complete complex tasks like mortgage applications easily</li>
            <li>Receive personalized recommendations</li>
          </ul>
          <p>
          All this, 24/7, without human intervention.
          </p>
        </div>
       
      </ContentSection>
      {/* Grid of Feature Cards */}
      <FeatureGrid>
        <GridCard>
          <h3>Conversational AI in Healthcare appointment scheduling</h3>
          <p> Human-like interactions with customers, answer their queries and schedule appointments into EMR.</p>
          <video className="video2" autoPlay muted loop loading="lazy" >
            <source src={video2} type="video/mp4" />
            <source src={video2_720} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </GridCard>
        <GridCard>
          <h3>Conversational AI in FinTech</h3>
          <p>Accelerate Revenue by automating customer interactions using Conversational-AI.
            Helping customers with all types of loans and mortgages.</p>
          <video className="video3" autoPlay muted loop loading="lazy" >
            <source  src={video3} type="video/mp4" />
            <source src={video3_720} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </GridCard>
      </FeatureGrid>
    </FeaturesSection>
    );
  };
  
  export default Features;
  