import { createGlobalStyle } from 'styled-components';

export const GlobalStyle = createGlobalStyle`
  * {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Roboto', sans-serif;
  }

  body {
    background-color: ${({ theme }) => theme.colors.background};
    color: ${({ theme }) => theme.colors.text};
    overflow-x: hidden;
  }

  a {
    text-decoration: none;
    color: inherit;
  }

  /* Responsive Typography */
  h1 {
    font-size: 2.5rem;
    @media (max-width: 768px) {
      font-size: 2rem;
    }
  }
  * {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}
html, body {
  overflow-x: hidden; /* Prevent horizontal scroll caused by overflow */
  max-width: 100%; /* Prevent body from expanding horizontally */
}


  p {
    font-size: 1.1rem;
    @media (max-width: 768px) {
      font-size: 1rem;
    }
  }
`;
