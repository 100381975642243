import React, { useState, useEffect, useRef } from 'react';
import styled, { keyframes } from 'styled-components';
import axios from 'axios';
import { FaMicrophone } from 'react-icons/fa'; // Mic icon
import Header from '../components/Header'; // Reusable Header component
import CircularProgress from '@mui/material/CircularProgress'; // Import Material-UI spinner


const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

const ContactSection = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  min-height: 100vh;
  background: ${({ theme }) => theme.colors.background};
`;

const Header2 = styled.div`
  width: 100%;
  padding: 2rem;
  background: ${({ theme }) => theme.colors.primary};
  text-align: center;

  h1 {
    font-size: 2rem;
    color: white;
    margin: 0;
    line-height: 1.2;
  }

  p {
    margin-top: 0.5rem;
    font-size: 1rem;
    color: #f0f0f0;
  }
`;

const SectionDivider = styled.div`
  width: 80%;
  max-width: 1200px;
  height: 1px;
  background: ${({ theme }) => theme.colors.secondary};
  margin: 2rem 0;
`;

const SectionContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 2rem;
  width: 80%;
  max-width: 1200px;
  margin: 2rem auto;

  @media (max-width: 768px) {
    flex-direction: column;
    gap: 1.5rem;
  }
`;
const Card = styled.div`
  background: white;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 2rem;
  flex: 1;
  text-align: center;

  h2 {
    font-size: 1.8rem;
    color: ${({ theme }) => theme.colors.primary};
    margin-bottom: 1rem;
  }

  p {
    font-size: 1.2rem;
    color: ${({ theme }) => theme.colors.text};
    margin: 0.5rem 0;
  }

  a {
    font-weight: bold;
    color: ${({ theme }) => theme.colors.primary};
    text-decoration: underline;
    cursor: pointer;
  }

  .call-btn {
    margin-top: 1rem;
    padding: 1rem 2rem;
    background: ${({ theme }) => theme.colors.primary};
    color: white;
    border: none;
    border-radius: 8px;
    font-size: 1rem;
    cursor: pointer;
    transition: all 0.3s;

    &:hover {
      background: ${({ theme }) => theme.colors.accent};
      transform: translateY(-2px);
    }
  }
`;

const ChatSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: #f9f9f9;
  width: 95%; /* Flexible width */
  max-width: 1000px;
  padding: 2.5rem;
  border-radius: 20px;
  box-shadow: 0 6px 18px rgba(0, 0, 0, 0.1);
  margin: 3rem 0;

  h2 {
    font-size: 2.4rem;
    color: ${({ theme }) => theme.colors.primary};
    margin-bottom: 2rem;
  }

  @media (max-width: 768px) {
    width: 100%;
    padding: 1rem;
    margin: 1rem 0;
  }
`;


const ChatWindow = styled.div`
  width: 100%;
  height: 600px;
  border: 2px solid ${({ theme }) => theme.colors.secondary};
  border-radius: 12px;
  overflow-y: auto;
  margin-bottom: 1.5rem;
  padding: 1.2rem;
  background: #ffffff;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  position: relative; /* Enables relative positioning for child elements */

  @media (max-width: 768px) {
    padding: 0.8rem;
    max-height: 60vh; /* Further reduce height for smaller screens */
  }

  @media (max-width: 480px) {
    max-height: 50vh; /* Compact height for very small devices */
  }
`;

const LoadingIndicator = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 1rem; /* Spacing below the last message */
`;

const Message = styled.div`
  max-width: 70%;
  padding: 0.8rem 1rem;
  border-radius: 16px;
  font-size: 1rem;
  line-height: 1.5;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  ${({ sender, theme }) =>
    sender === 'user'
      ? `
    align-self: flex-end;
    background: ${theme.colors.primary};
    color: white;
    border-bottom-right-radius: 0;
  `
      : `
    align-self: flex-start;
    background: #f1f1f1;
    color: ${theme.colors.text};
    border-bottom-left-radius: 0;
  `};
  @media (max-width: 768px) {
    font-size: 0.9rem; /* Reduce font size for smaller screens */
    padding: 0.6rem 0.8rem;
  }
`;

const ChatInput = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  gap: 1rem;
  padding: 0.5rem;

  input {
    flex: 1;
    padding: 1rem;
    border: 2px solid ${({ theme }) => theme.colors.secondary};
    border-radius: 12px;
    outline: none;
    font-size: 1.2rem;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    background-color: ${({ disabled }) => (disabled ? '#f0f0f0' : '#fff')};
    pointer-events: ${({ disabled }) => (disabled ? 'none' : 'auto')};
  }

  button {
    padding: 1rem 1.5rem;
    background: ${({ theme, disabled }) =>
      disabled ? '#ddd' : theme.colors.primary};
    color: ${({ disabled }) => (disabled ? '#aaa' : 'white')};
    border: none;
    border-radius: 12px;
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
    font-size: 1.1rem;
    font-weight: bold;
    transition: all 0.3s;

    &:hover {
      background: ${({ theme, disabled }) =>
        disabled ? '#ddd' : theme.colors.accent};
      transform: ${({ disabled }) => (disabled ? 'none' : 'translateY(-2px)')};
    }
  }

  .mic-icon {
    font-size: 1.8rem;
    color: ${({ theme, disabled }) =>
      disabled ? '#aaa' : theme.colors.primary};
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
    transition: transform 0.3s;

    &:hover {
      transform: ${({ disabled }) => (disabled ? 'none' : 'scale(1.3)')};
    }
  }

  @media (max-width: 768px) {
    gap: 0.5rem;
    padding: 0.8rem; /* Adjust padding for smaller devices */

    input {
      font-size: 1rem; /* Reduce input size */
      padding: 0.8rem; /* Reduce padding */
    }

    button {
      padding: 0.8rem 1.2rem; /* Smaller button size for mobile */
      font-size: 1rem; /* Adjust font size */
    }

    .mic-icon {
      font-size: 1.5rem; /* Further reduce icon size */
    }
  }
`;

const Contact = () => {
  const [messages, setMessages] = useState([]);
  const [userInput, setUserInput] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const messagesEndRef = useRef(null);
const [isInitialRender, setIsInitialRender] = useState(true);

useEffect(() => {
  if (!isInitialRender) {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  } else {
    setIsInitialRender(false);
  }
}, [messages]);


  const handleSendMessage = async () => {
    if (!userInput.trim()) return;

    const newMessages = [...messages, { sender: 'user', text: userInput }];
    setMessages(newMessages);
    setUserInput('');
    setIsLoading(true);

    const headers = {};
    headers['Content-Type'] = 'application/json';
    axios.defaults.withCredentials = true;

    try {
      const response = await axios.post('https://support.kudiltech.com/ask-myna', {
        query: userInput,
        languageCode: 'en-US',
        agentName: 'kudiltech'
      });
      const agentResponse = response.data.response || "I'm sorry, I didn't catch that.";
      setMessages((prev) => [...prev, { sender: 'agent', text: agentResponse }]);
    } catch (error) {
      console.error('Error:', error);
      setMessages((prev) => [...prev, { sender: 'agent', text: 'Something went wrong!' }]);
    } finally {
      setIsLoading(false);
    }
    setUserInput('');
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      handleSendMessage();
    }
  };

  const startSpeechRecognition = () => {
    const recognition = new (window.SpeechRecognition || window.webkitSpeechRecognition)();
    recognition.lang = 'en-US';
    recognition.interimResults = false;

    recognition.onresult = (event) => {
      setUserInput(event.results[0][0].transcript);
    };

    recognition.start();
  };

  return (
    <ContactSection>
      <Header />
      <Header2>
        <h1>Contact Us</h1>
        <p>Get in touch with our team or chat with us below!</p>
      </Header2>
      <SectionDivider />
      <SectionContainer>
        <Card>
          <h2>Call Us</h2>
          <p>Prefer to speak directly? Give us a call!</p>
          <p className="phone">+1 323 814 5336</p>
          <button className="call-btn" onClick={() => (window.location.href = 'tel:+13238145336')}>
            Call Now
          </button>
        </Card>
        <Card>
          <h2>Our Office</h2>
          <p>8718 Club Estates Way</p>
          <p>Lake Worth, FL 33467</p>
            <p>
              Email:{' '}
              <a href="mailto:support@kudiltech.com"
                className="email">
                support@kudiltech.com
              </a>
            </p>
        </Card>
      </SectionContainer>
      <SectionDivider />
      <ChatSection>
        <h2>Chat with Us</h2>       
        <ChatWindow>
          {messages.map((msg, index) => (
            <Message key={index} sender={msg.sender}>
              {msg.text}
            </Message>
          ))}
  {isLoading && (
    <LoadingIndicator>
      <CircularProgress />
    </LoadingIndicator>
  )}
        <div ref={messagesEndRef} />
        </ChatWindow>

        <ChatInput disabled={isLoading}>
          <input
            type="text"
            value={userInput}
            onChange={(e) => setUserInput(e.target.value)}
            onKeyDown={handleKeyDown}
            placeholder="Type your message or speak..."
            disabled={isLoading}
          />
          <button onClick={handleSendMessage} disabled={isLoading}>
    Send
  </button>
  <FaMicrophone
    className="mic-icon"
    onClick={startSpeechRecognition}
    disabled={isLoading}
  />
        </ChatInput></ChatSection> </ContactSection>
  );
};

export default Contact;