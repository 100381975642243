import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

const FooterContainer = styled.footer`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1.5rem 2rem;
  background: ${({ theme }) => theme.colors.secondary};
  color: white;
  margin-top: auto;
  position: relative;

  @media (min-width: 769px) {
    flex-direction: row;
    justify-content: center;
  }

  p {
    font-size: 1rem;
    margin: 0;
    text-align: center;

    @media (max-width: 768px) {
      font-size: 0.9rem;
    }
  }

  .privacy-link {
    margin-top: 0.5rem;
    text-decoration: none;
    color: ${({ theme }) => theme.colors.textLight};
    font-weight: bold;

    &:hover {
      text-decoration: underline;
    }

    @media (min-width: 769px) {
      margin-top: 0;
      position: absolute;
      right: 2rem;
      top: 50%;
      transform: translateY(-50%);
    }
  }
`;

const Footer = () => (
  <FooterContainer>
    <p>© 2024 Kudiltech - All Rights Reserved</p>
    <Link to="/privacy" className="privacy-link">
      Privacy
    </Link>
  </FooterContainer>
);

export default Footer;
