export const theme = {
    colors: {
      primary: '#4A90E2',      // Main blue color
      secondary: '#2C3E50',    // Dark gray
      accent: '#F39C12',       // Accent yellow
      background: '#f4f4f9',   // Light gray background
      text: '#333333',         // Text color
    },
    breakpoints: {
      mobile: '768px',
    },
  };
  