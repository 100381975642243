import React, { useState } from 'react';
import styled from 'styled-components';
import { motion } from 'framer-motion';
import { useNavigate } from 'react-router-dom'; // Replace useHistory with useNavigate

const HeroSection = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center; /* Ensures content is centered vertically */
  align-items: center;
  min-height: 10vh; /* Reduced height */
  padding: 1.5rem 1rem; /* Reduced padding */
  text-align: center;
  background: ${({ theme }) => theme.colors.primary};
  color: white;

  h1 {
    font-size: 2.5rem; /* Slightly smaller font size */
    margin-bottom: 1rem; /* Add some spacing below heading */
  }

  p {
    max-width: 600px; /* Slightly narrower content width */
    margin: 0.5rem 0; /* Reduced vertical spacing */
  }

  @media (max-width: 768px) {
    min-height: 50vh; /* Further reduced height for smaller screens */
    padding: 2rem 1rem;

    h1 {
      font-size: 2rem;
    }
  }
`;

const HeroButton = styled(motion.button)`
  margin-top: 1rem;
  padding: 0.8rem 1.5rem; /* Adjust button padding */
  background: ${({ theme }) => theme.colors.accent};
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;

  &:hover {
    background-color: ${({ theme }) => theme.colors.secondary};
  }
`;

const Hero = () => {
  const navigate = useNavigate(); // Initialize useNavigate hook

  const handleLearnMoreClick = () => {
    navigate('/myna'); // Navigate to the Myna page
  };


  return (
    <HeroSection>
      <motion.h1
        initial={{ opacity: 0, y: -30 }}
        animate={{ opacity: 1, y: 0 }}
        transition={{ duration: 0.8 }}
      >
        Transforming Applications with Conversational AI
        </motion.h1>
      <motion.p
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{ delay: 0.4, duration: 0.8 }}
      >
       Enable businesses with intelligent, human-like conversations with our advanced Conversational AI solutions.
        </motion.p>
      <HeroButton
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
        onClick={handleLearnMoreClick} // Updated click handler
      >
       TRY IT YOURSELF!!
      </HeroButton>
    </HeroSection>
  );
};

export default Hero;
