import React from 'react';
import Header from '../components/Header';
import Hero from '../components/Hero';
import Features from '../components/Features';

const Home = () => (
  <>
    <Header />
    <Hero />
    <Features />
  </>
);

export default Home;
