import React, { useState, useEffect } from "react";

const TypingEffect = ({ text, speed = 200 }) => {
  const [displayedText, setDisplayedText] = useState("");
  const [wordIndex, setWordIndex] = useState(0);

  useEffect(() => {
    const words = text.split(" ");
    if (wordIndex < words.length) {
      const timer = setTimeout(() => {
        setDisplayedText((prev) => (prev ? `${prev} ${words[wordIndex]}` : words[wordIndex]));
        setWordIndex(wordIndex + 1);
      }, speed);

      return () => clearTimeout(timer); // Clean up the timeout
    }
  }, [wordIndex, text, speed]);

  return (
    <p style={{ fontStyle: "italic", color: "#4A90E2", fontSize: "18px", lineHeight: "1.6" }}>
      {displayedText}
    </p>
  );
};

export default TypingEffect;
