import React from 'react';
import styled from 'styled-components';
import Header from '../components/Header'; // Import reusable Header component
import { useNavigate } from 'react-router-dom'; // Replace useHistory with useNavigate
import { motion } from 'framer-motion';



const PrivacySection = styled.section`
  padding: 4rem 2rem;
  max-width: 800px;
  margin: 0 auto;
  text-align: justify;
  color: ${({ theme }) => theme.colors.text};
  line-height: 1.8;
  background: ${({ theme }) => theme.colors.background};
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
  border-radius: 10px;

  h1 {
    font-size: 2.5rem;
    font-weight: bold;
    margin-bottom: 2rem;
    text-align: center;
    color: ${({ theme }) => theme.colors.primary};
  }
  a {
    text-decoration: underline;
  }
  p {
    font-size: 1.1rem;
    margin-bottom: 1.5rem;
  }
  .contact-link {
    text-decoration: none;
    color: ${({ theme }) => theme.colors.textLight};
    font-weight: bold;

    &:hover {
      text-decoration: underline;
    }
   

  @media (max-width: 768px) {
    padding: 2rem 1.5rem;

    h1 {
      font-size: 2rem;
    }

    p {
      font-size: 1rem;
    }
    a {
      text-decoration: underline;
    }
  }
`;
const HeroButton = styled(motion.button)`
  margin-top: 0.4rem;
  padding: 0.2rem 0.8rem; /* Adjust button padding */
  background: ${({ theme }) => theme.colors.accent};
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 5px;

  &:hover {
    background-color: ${({ theme }) => theme.colors.secondary};
  }
`;


const Privacy = () => {

  const navigate = useNavigate(); // Initialize useNavigate hook

  const handleLearnMoreClick = () => {
    navigate('/contact'); // Navigate to the Myna page
  };
  return (
    <><Header /><PrivacySection>
      <h1>Privacy Policy</h1>
      <p>
        Your privacy is important to us. This policy explains what information we collect, how we use it, and your rights regarding your data.
      </p>
      <p>
        We may collect personal data such as your name, email, and phone number when you interact with our services. This information is used to improve our offerings and provide a better user experience.
        We are committed to protecting your personal information and your right to privacy. If you have any questions or concerns about our policy, or our practices with regards to your personal information, please contact us.

        This Privacy Policy governs practices of our website located at <a href="https://www.kudiltech.com">www.kudiltech.com</a>. Please read our Privacy Policy carefully as it will help you make informed decision about sharing your personal information with us.
      </p>
      <p>
        Your data is stored securely and will never be shared with third parties without your explicit consent. For more details, you can reach out to us at  <HeroButton
        whileHover={{ scale: 1.05 }}
        whileTap={{ scale: 0.95 }}
        onClick={handleLearnMoreClick} // Updated click handler
      >
       HERE
      </HeroButton>
      </p>
    </PrivacySection></>
);
};

export default Privacy;
