import React from 'react';
import '../styles/ChatOptions.css';

const ChatOptions = ({ options, onOptionSelect }) => {
  // Check if options is an array, if not return an empty array
  const validOptions = Array.isArray(options) ? options : [];

  return (
    <div className="options">
      {validOptions.length > 0 ? (
        validOptions.map((option, index) => (
          <button key={index} className="option-button" onClick={() => onOptionSelect(option)}>
            {option}
          </button>
        ))
      ) : (
        <div className="no-options"></div>
      )}
    </div>
  );
};

export default ChatOptions;
