import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import Header from '../components/Header'; // Import your existing Header component
import axios from 'axios';

export const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #f9f9f9;
`;

export const Content = styled.div`
  flex: 1;
  padding: 2rem;
  max-width: 1200px;
  margin: 0 auto;
`;

export const Title = styled.h1`
  text-align: center;
  font-size: 2.5rem;
  color: #333;
  margin-bottom: 2rem;
`;

export const ToggleButtons = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;

  button {
    padding: 0.8rem 1.5rem;
    margin: 0 0.5rem;
    font-size: 1rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    background-color: #e0e0e0;
    color: #333;
    transition: 0.3s;

    &.active {
      background-color: #007bff;
      color: white;
    }

    &:hover {
      background-color: #0056b3;
      color: white;
    }
  }
`;

export const TableContainer = styled.div`
  background: white;
  padding: 1rem;
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  overflow-x: auto;
`;

export const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;

  th,
  td {
    padding: 1rem;
    text-align: left;
    border-bottom: 1px solid #ddd;
  }

  th {
    background-color: #f5f5f5;
    text-transform: uppercase;
    color: #555;
  }

  tr:hover {
    background-color: #f9f9f9;
  }
`;

export const Loading = styled.div`
  text-align: center;
  font-size: 1.5rem;
  color: #888;
`;

const Dashboard = () => {
  const [activeTable, setActiveTable] = useState('chats');
  const [tableData, setTableData] = useState([]);
  const [loading, setLoading] = useState(true);

  const fetchTableData = async (tableType) => {
    setLoading(true);
    const headers = {};
    headers['Content-Type'] = 'application/json';
    axios.defaults.withCredentials = true;
    try {
      const response = await axios.get(`https://support.kudiltech.com/api/v1/${tableType}`);
      if (activeTable === 'chats') {
        setTableData(response.data.chats);
      } else {
        setTableData(response.data.calls);
      }
    } catch (error) {
      console.error('Error fetching table data:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchTableData(activeTable);
  }, [activeTable]);

  return (
    <PageWrapper>
      <Header />
      <Content>
        <Title>Dashboard</Title>
        <ToggleButtons>
          <button
          className={activeTable === 'chats' ? 'active' : ''}
          onClick={() => setActiveTable('chats')}
        >
          Chats
        </button>
        <button
          className={activeTable === 'calls' ? 'active' : ''}
          onClick={() => setActiveTable('calls')}
        >
          Calls
        </button>
      </ToggleButtons>
      <TableContainer>
          {loading ? (
            <Loading>Loading...</Loading>
          ) : (
            <StyledTable>
              <thead>
                <tr>
                <th>Session ID</th>
                <th>Tenant ID</th>
                <th>First Name</th>
                <th>Last Name</th>
                <th>Email</th>
                <th>Phone Number</th>
                <th>Created Date</th>
                <th>Updated Date</th>
              </tr>
            </thead>
            <tbody>
              {tableData.map((row, index) => (
                <tr key={index}>
                  <td>{row.sessionId}</td>
                  <td>{row.tenantId}</td>
                  <td>{row.firstName}</td>
                  <td>{row.lastName}</td>
                  <td>{row.email}</td>
                  <td>{row.phone}</td>
                  <td>{row.systemCreatedDate}</td>
                  <td>{row.systemUpdatedDate}</td>
                  </tr>
                ))}
              </tbody>
            </StyledTable>
          )}
        </TableContainer>
      </Content>
    </PageWrapper>
  );
};

export default Dashboard;